import React from "react";

const NotFoundPage = () => {
  return (
    <div
      className="not-found-container"
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <h1>404 Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
